import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch,useSelector } from "react-redux";
import _ from "lodash"
import moment from 'moment'
import { setShowSpinner } from "../../../../../../redux/spinners/spinners"
import { setActiveModal } from "../../../../../../redux/app/appSaver";
import { Autocomplete, TextField, Modal, CircularProgress } from '@mui/material';
import { useParams} from "react-router-dom";
import 'date-fns';
import { rdx, useOrder } from "../../../../../../redux/orders/order"
import { useFetch, recordFrontEndError } from '../../../../../../utlis/api'
import { setAllLocations } from "../../../../../../redux/allLocations";
import * as api from '../../../../../../utlis/api'
import { updateChangesArray, updateCopySection, updateCopySubSection, updateStatus } from "../../../../../../redux/saveAndCopy"
import { addTestingTag } from "../../../../../../utlis/testingHelpers";

//TODO: Add Form errors
const DetailsModal = ({ departments, setDepartments, updateLocation }) => {
  const axios     = useFetch()

  const dispatch        = useDispatch()
  const { id: orderId } = useParams()
  const order           = useOrder()

  const isOpen              = useIsOpen()
  const locations           = useLocations()
  const orderStatuses       = useOrderStatuses()
  const inputStatusValue    = useInputStatusValue()
  const salesReps           = useSalesReps()
  const salesManagers       = useSalesManagers()
  
  const saveSuccess         = useSaveSuccess()
  const hasUnsavedChanges   = useHasUnsavedChanges()
  const editedOrderDetails  = useEditedOrderDetails()
  const {showSpinner} = useSelector((state)=>state.spinners);
  const appData = useSelector((state) => state?.appData);
  const role = useSelector((state) => state?.role.role);
  const me = useSelector((state) => state?.me);
  const allLocations = useSelector((state) => state?.allLocations);

  const [ tempLocationOptions, setTempLocationOptions ]   = useState([])
  // const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  const [dataChangeArray, setDataChangeArray]   = useState([]);
  async function updateDataChangeArray(prop, value){
    // e.preventDefault();

    // const { name: prop, value} = e.target;

    if(prop === 'tecLocationCode'){
      let foundLocation = allLocations.find( l => l.locationCode === value);
      if(foundLocation){
        let res = await api.getTecRegion(foundLocation?.locationCode);
        if(res){
          let region = res?.data;
          // foundLocation.email = region?.email;
          let builtLocation = Object.assign({}, foundLocation , {email: region.email});
          
          prop = 'location';
          value = builtLocation;
        }
      }else{
        return;
      }
    }

    let doNotCopyProperties = [];

    if(dataChangeArray && !doNotCopyProperties.includes(prop)){

      if(dataChangeArray[prop]?.oldValue){

        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": dataChangeArray[prop].oldValue
          }
        }))
        
      }else{
        const oldValue = editedOrDefaultOrderDetails[prop];

        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": oldValue ? oldValue : '--'
          }
        }))
      }
    }
  }

  useEffect(() => {

    if(appData?.activeModal === 'details'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])


  // Load All Locations
  useEffect(() => {

    
    if(appData?.activeModal === 'details'){

      async function loadLocations(){
        let foundLocations = await api.getTecLocation();
        if(foundLocations && foundLocations.data?.data?.length > 0){
          dispatch(setAllLocations(foundLocations.data?.data));
        }
      }


      if (!allLocations || allLocations.length === 0) {
        loadLocations();
      }

    }

  }, [allLocations, appData?.activeModal, dispatch]);

  //For displaying either the edited order details or the details from the order.
  const editedOrDefaultOrderDetails = useMemo(() => {

    const detailsFromOrder = {
      ...order,
      orderDate: moment.utc(order.orderDate).format("YYYY-MM-DD"),
      documentationDate: order.documentationDate ?
        (moment.utc(order.documentationDate).format("YYYY-MM-DD")) : null,
      dateFinalized: order.dateFinalized ?
        (moment.utc(order.dateFinalized).format("YYYY-MM-DD")) : null,
      orderStatusId: order.orderStatus?.id,
    }

    //Modify {}, pulling values from the edited order details, then the order
    return _.defaults({}, editedOrderDetails, detailsFromOrder)

  }, [ editedOrderDetails, order ])

  const [hasClickedClose, setHasClickedClose] = useState(false);

  const closeClicked = e => {
    if( hasUnsavedChanges ){
      setHasClickedClose(true);
    } else {
      onCloseClick(e);
    }
  }

  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }

  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  //Effect to fetch various data on mount...
  useEffect(() => {

    
    if(appData?.activeModal === 'details'){

      dispatch(rdx.getOrderStatus())
      dispatch(rdx.getSalesManagers())
      dispatch(rdx.getSalesReps())
      dispatch(rdx.getLocations())

    }

  }, [appData?.activeModal, dispatch])


  useEffect(() => {

    
    if(appData?.activeModal === 'details'){


      if(role !== 'master' && me?.additionalLocationCodes?.length > 0 && allLocations?.length > 0 && locations?.length > 0 && tempLocationOptions?.length === 0){
        let tempOptions = [];

        let additionalLocationCodes = me?.additionalLocationCodes;
        additionalLocationCodes.forEach(addCode => {
            var foundLocation = allLocations.find(loc => loc.locationCode === addCode);
            if(foundLocation){
              let foundInMainOptions = locations.find(newLoc => newLoc.code === foundLocation.locationCode);
              let foundInTempOptions = tempOptions.find(newLoc => newLoc.code === foundLocation.locationCode);
              if(!foundInMainOptions && !foundInTempOptions){
                tempOptions.push({
                  locationCode: foundLocation.locationCode,
                  description: foundLocation.description
                });
              }
            }
        });

        setTempLocationOptions(tempOptions)
      }

    }

  }, [allLocations, appData?.activeModal, locations, me, me?.additionalLocationCodes, role, tempLocationOptions, tempLocationOptions?.length])

  function preparedLocations(){
    let preparedLocationsArray = [];
    
    if(locations && locations.length > 0){
      locations.forEach( loc => {
        preparedLocationsArray.push(loc);
      })
    }

    if(tempLocationOptions && locations.length > 0){
      tempLocationOptions.forEach( loc => {
        preparedLocationsArray.push(loc);
      })
    }


    return preparedLocationsArray;
  }

  // }, [ order, locations, departments, setDepartments ])

  const getLocationSalesDepartments = useCallback(async (location) => {
    try{
      const res = await axios.get(`/tec-location/${location.locationCode}/sales-departments`)
      setDepartments(res?.data)
    }catch(err) {
      recordFrontEndError('DetailsModal.jsx', 'Error getting location sales departments: '+err)
    }
  },[ axios, setDepartments ]);

  useEffect(() => {

    
    if(appData?.activeModal === 'details'){

      if(order && order.tecLocationCode && allLocations && allLocations.length > 0 && !departments){
        let location_code = order.tecLocationCode;
        if(location_code){
          let foundLocation = allLocations.find((location) => location.locationCode === location_code);
          if(foundLocation){
            getLocationSalesDepartments(foundLocation);
          }
        }
      }

    }

  }, [order, departments, getLocationSalesDepartments, allLocations, appData?.activeModal])

  /* Event Handlers */
  const onCloseClick = e => {
    e.preventDefault()

    dispatch(rdx.setIsOpen(false))
    dispatch(rdx.resetValues())
    dispatch(setActiveModal(null));
  }

  const onInputStatusChange = ( event, newInputValue ) => {
    dispatch(rdx.setInputStatusValue(newInputValue))
    dispatch(rdx.setSaveSuccess(false))
    // dispatch(rdx.setHasUnsavedChanges(true))
  }

  const onFieldChange = (e, newValue) => {

    console.log('SIT onFieldChange e, newValue', e, newValue)

    let { name: field, value} = e.target;
    if(e.target.attributes?.name.value && !field){
      field = e.target.attributes.name.value
    }

    console.log('SIT onFieldChange field', field)


    if(newValue){
      value = newValue;
    }

    if (field) {
      if(field === 'tecLocationCode'){
        dispatch(rdx.setEditedOrderDetails('tecLocationCode', value.locationCode))
        updateDataChangeArray(field, value.locationCode);
      }else if(field === 'salesRepId'){
        dispatch(rdx.setEditedOrderDetails('salesRepId', value.employee_ID));
        updateDataChangeArray(field, value.employee_ID);
      }else if(field === 'salesManagerId'){
        dispatch(rdx.setEditedOrderDetails('salesManagerId', value.employee_ID));
        updateDataChangeArray(field, value.employee_ID);
      }else if(field === 'orderStatusId'){
        dispatch(rdx.setEditedOrderDetails('orderStatus', value));
        updateDataChangeArray(field, value.id);
      } else {
        dispatch(rdx.setEditedOrderDetails(field, value))
        updateDataChangeArray(field, value);
      }
      dispatch(rdx.setSaveSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch(setShowSpinner(true));

    let foundLocation = locations.find((location) => location.locationCode === editedOrderDetails.tecLocationCode);
    if(foundLocation){
      getLocationSalesDepartments(foundLocation);
      updateLocation(editedOrderDetails.tecLocationCode);
    }

    const ORDER_DETAILS_FIELDS = [
      'orderDate', 
      'orderStatusId', 
      'locationId',
      'documentationDate', 
      'dateFinalized', 
      'salesRepId', 
      'salesRepName', 
      'salesManagerId', 
      'salesManagerName', 
      'fob', 
      'specialInstructions'
    ]

    //Choose fields from the order, adding the order status id and location id
    const detailsFromOrder = _.pick({
      ...order,
      orderStatusId: order.orderStatus.id,
      tecLocationCode: order.tecLocationCode,
      salesRepId: order.salesRepId,
      salesManagerId: order.salesManagerId
    }, ORDER_DETAILS_FIELDS)

    const detailsFromEdit = {
      ...editedOrderDetails,
      orderStatusId: editedOrderDetails.orderStatus?.id,
      tecLocationCode: (editedOrderDetails.tecLocationCode ? editedOrderDetails.tecLocationCode :  order.tecLocationCode),
      salesRepId: (editedOrderDetails.salesRepId ? editedOrderDetails.salesRepId :  order.salesRepId),
      salesRepName: (editedOrderDetails.salesRepId ? "" :  editedOrderDetails.salesRepName),
      salesManagerId: (editedOrderDetails.salesManagerId ? editedOrderDetails.salesManagerId :  order.salesManagerId),
      salesManagerName: (editedOrderDetails.salesManagerId ? "" :  editedOrderDetails.salesManagerName)
    }

    const orderDetails = _.defaults({}, detailsFromEdit, detailsFromOrder)

    //Save
      dispatch(rdx.updateOrderDetails(orderId, orderDetails))
      dispatch(rdx.setHasUnsavedChanges(false))
      

  }

  function getLocationByCode(code){
    return preparedLocations().find(location => location.locationCode === code);
  }

  function getRepByEmployeeId(employeeId){
    if(salesReps && salesReps.length > 0){
      return salesReps.find(rep => rep.employee_ID === employeeId);
    }
  }

  function getManagerByEmployeeId(employeeId){
    if(salesManagers && salesManagers.length > 0){
      return salesManagers.find(manager => manager.employee_ID === employeeId);
    }
  }

  return (
    <>
      { appData?.activeModal === 'details' ? (
        <>
          <Modal
            style={{ overflow: 'scroll' }}
            open={ isOpen }
            onClose={ onCloseClick }>
            <div>
              { order ? (
              <div id="detailsmodal" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                  <div className="modal-content">
                    <div className="modal-header bg-primary text-white">
                      <h5
                        className="modal-title textUppercase"
                        id="exampledetailsmodel"
                      >
                        UPDATE ORDER DETAILS
                      </h5>
                      { hasClickedClose ? (
                        <div className="d-flex">
                          <p className="m-1">Save Changes?</p>
                          <button className="m-1 btn btn-sm btn-success" aria-label='save' data-testid="details.control.saveYes" onClick={preCloseSave}>Yes</button>
                          <button className="m-1 btn btn-sm btn-danger" aria-label='do not save' data-testid="details.control.saveNo" onClick={preCloseClose}>No</button>
                        </div>
                      ):(
                        <button
                          type="button"
                          className="btn-close bg-white"
                          data-testid="details.control.close"
                          // data-bs-dismiss="modal"
                          aria-label="close"
                          onClick={ closeClicked }
                        ></button>
                      )}

                    </div>

                    {/* MODLE BODY START HERE */}
                    <div className="modal-body">
                      <div className="container">
                          <div className="d-flex">
                          {showSpinner? <div
                      style={{
                        display: "flex",
                        width: "100vw",
                        height:'13vh',
                        justifyContent: "center",
                      }}
                    >
                <CircularProgress style={{ placeSelf: "center" }} />
              </div>:( saveSuccess ? 
                            <>
                              <button
                                type="button"
                                className="mx-auto btn btn-success mx-auto btn-sm"
                                aria-label="save success"
                                >
                                Saved Order Details
                              </button>
                              <button aria-label='Copy to Documents' 
                                onClick={ (e)=>{
                                  e.preventDefault();
                                  dispatch(rdx.resetValues())
                                  dispatch(updateCopySection('details'));
                                  dispatch(updateCopySubSection('details'));
                                  dispatch(updateStatus('copy'));
                                  dispatch(setActiveModal('copy-to-documents'));
                                }} data-bs-toggle="modal" data-testid="details.control.copyToDocuments" data-bs-target="#copychangestodocuments" className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button><br />        
                            </>
                          :
                            <button onClick={handleSubmit} aria-label='save' data-testid="details.control.saveTop" className="mx-auto btn btn-primary text-uppercase mx-auto">Save</button>
                          )}
                          </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="fw-bold text-secondary my-1 textCapitalize">
                              <label htmlFor="orderDate" className="form-label">
                                Order Date
                              </label>
                              <input
                              type="date"
                              className="rounded-pill form-control"
                              name="orderDate"
                              onChange={ onFieldChange }
                              aria-label='order date'
                              data-testid="details.orderDate"
                              value={ editedOrDefaultOrderDetails.orderDate }
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="fw-bold text-secondary my-1 ">
                              <label htmlFor="documentationDate" className="form-label">
                                Documentation Date
                              </label>
                              <input
                              type="date"
                              className="rounded-pill form-control"
                              name="documentationDate"
                              data-testid="details.documentationDate"
                              aria-label='documentation date'
                              onChange={(e) => onFieldChange(e)}
                              value={ editedOrDefaultOrderDetails.documentationDate }
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="fw-bold text-secondary my-1 ">
                              <label htmlFor="dateFinalized" className="form-label">
                                Date Finalized
                              </label>
                              <input
                              type="date"
                              className="rounded-pill form-control"
                              data-testid="details.dateFinalized"
                              name="dateFinalized"
                              aria-label="date finalized"
                              onChange={(e) => onFieldChange(e)}
                              value={ editedOrDefaultOrderDetails.dateFinalized }
                              />
                            </div>
                          </div>
                        </div>
                        
                        <div className="row">
                          <div className="col-6">
                            <div className="fw-bold text-secondary my-3 ">
                              <Autocomplete
                                value={ editedOrDefaultOrderDetails.orderStatus ?
                                  editedOrDefaultOrderDetails.orderStatus : '--' }
                                onChange={ onFieldChange }
                                getOptionLabel={ option => option.name }
                                inputValue={inputStatusValue}
                                name="orderStatusId"
                                onInputChange={ onInputStatusChange }
                                aria-label='order status'
                                options={ orderStatuses }
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...addTestingTag(params, 'details.orderStatusId')} label="STATUS" />}
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.id} name='orderStatusId'>
                                      {option.name}
                                    </li>
                                  );
                                }}
                              />
                            </div>
                          </div>
                            <div className="col-6">
                              <div className="fw-bold text-secondary my-3 ">
                                <Autocomplete
                                  value={ getLocationByCode(editedOrDefaultOrderDetails.tecLocationCode)??{locationCode: null, description: "LOCATION"} }
                                  onChange={ onFieldChange }
                                  name="tecLocationCode"
                                  getOptionLabel={ location => location.description }
                                    // inputValue={ editedOrDefaultOrderDetails.tecLocationCode }
                                    // onInputChange={ onLocationInputChange }
                                  aria-label='order location'
                                  options={preparedLocations()??[]}
                                  sx={{ width: 300 }}
                                  renderInput={(params) => <TextField {...addTestingTag(params, 'details.tecLocationCode')} label="LOCATION" />}
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.locationCode} name='tecLocationCode'>
                                        {option.description}
                                      </li>
                                    );
                                  }}
                                  />
                                </div>
                              </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <div className="fw-bold text-secondary my-3 ">
                              <Autocomplete
                                value={getRepByEmployeeId(editedOrDefaultOrderDetails?.salesRepId)??{employee_ID: null, firstName: "SALES REP", lastName: ""} }
                                onChange={ onFieldChange }
                                getOptionLabel={ option => option.preferredName ? option.firstName+' ('+option.preferredName+') '+option.lastName : option.firstName+' '+option.lastName }
                                // inputValue={ editedOrDefaultOrderDetails.tecLocationCode }
                                  // onInputChange={ onLocationInputChange }
                                aria-label='order sales rep'
                                name='salesRepId'
                                options={salesReps.sort((a, b) => -a.type.localeCompare(b.type))}
                                groupBy={(option) => option.type}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...addTestingTag(params, 'details.salesRepId')} label="SALES REP" />}
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.employee_ID} name='salesRepId'>
                                      {option.preferredName ? option.firstName+' ('+option.preferredName+') '+option.lastName : option.firstName+' '+option.lastName }
                                    </li>
                                  );
                                }}
                                />
                            </div>
                          </div>
                          <div className="col-6">
                              <div className="fw-bold text-secondary my-3 ">
                                <Autocomplete
                                  value={ getManagerByEmployeeId(editedOrDefaultOrderDetails.salesManagerId)??{employee_ID: null, firstName: "SALES MANAGER", lastName: ""} }
                                  onChange={ onFieldChange }
                                  getOptionLabel={ option => option.preferredName ? option.firstName+' ('+option.preferredName+') '+option.lastName : option.firstName+' '+option.lastName }
                                  // inputValue={ editedOrDefaultOrderDetails.tecLocationCode }
                                    // onInputChange={ onLocationInputChange }
                                  aria-label='order sales manager'
                                  name="salesManagerId"
                                  options={salesManagers}
                                  sx={{ width: 300 }}
                                  disabled={(salesManagers?.length === 0 || !salesManagers)}
                                  renderInput={(params) => <TextField {...addTestingTag(params, 'details.salesManagerId')} label="SALES MANAGER" />}
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.employee_ID} name='salesManagerId'>
                                        {option.preferredName ? option.firstName+' ('+option.preferredName+') '+option.lastName : option.firstName+' '+option.lastName}
                                      </li>
                                    );
                                  }}
                                  />
                              </div>
                          </div>
                        </div>
                        
                        
                        

                        <br />

                        <div className="row">
                          <div className="col-12 fw-bold text-secondary">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">
                              SPECIAL INSTRUCTIONS (<span style={{color:'red',}}>Shown on Sales Order</span>)
                            </label>
                            <textarea
                              className="form-control rounded"
                              id="exampleFormControlTextarea1"
                              aria-label='special order instructions'
                              rows="4"
                              maxLength="500"
                              name="specialInstructions"
                              onChange={(e) => onFieldChange(e)}
                              data-testid="details.specialInstructions"
                              value={ editedOrDefaultOrderDetails.specialInstructions }
                              ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MODLE BODY END HERE */}

                    <div className="modal-footer">
                    {showSpinner ? (
                      <div
                        style={{
                          display: "flex",
                          width: "100vw",
                          height:'13vh',
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress style={{ placeSelf: "center" }} />
                      </div>
                    ) : saveSuccess ? ( 
                      <>
                        <button
                          type="button"
                          className="mx-auto btn btn-sm btn-success mx-auto"
                          aria-label='save success'
                          >
                          Saved Delivery Details
                        </button>
                        <button aria-label='Copy to Documents' 
                        onClick={ (e)=>{
                          e.preventDefault();
                          dispatch(rdx.resetValues())
                          dispatch(updateCopySection('details'));
                          dispatch(updateCopySubSection('details'));
                          dispatch(updateStatus('copy'));
                          dispatch(setActiveModal('copy-to-documents'));
                        }} data-bs-toggle="modal" data-testid="details.control.copyToDocuments" data-bs-target="#copychangestodocuments" className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button><br />
                      </>
                    ) : (
                      <button onClick={handleSubmit} aria-label='save'  data-testid="details.control.saveBottom" className="mx-auto btn btn-primary text-uppercase mx-auto">Save</button>
                    )}
                    </div>
                  </div>
                </div>
              </div>
              ) : (<span></span>)}
            </div>
          </Modal>
        </>
      ):null}
    </>
  )
}

const {
  useIsOpen,
  useLocations,
  useOrderStatuses,
  useInputStatusValue,
  useSaveSuccess,
  // useInputLocationValue,
  useEditedOrderDetails,
  useHasUnsavedChanges,
  useSalesReps,
  useSalesManagers
  // useTecLocationCode
} = rdx

export default DetailsModal;
